import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Spacer,
  Text,
  useColorMode,
} from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import AnimatedCountUpDecimal from "../../components/AnimatedCountUpDecimal"
import BannerHyaloron from "../../components/choc/BannerHyaloron"
import BannerVeoJadeklinik from "../../components/choc/BannerVeoJadeklinik"
import LogoHeadlineTeaser from "../../components/choc/LogoHeadlineTeaser"
import LogoHeadlineTeaserVeo from "../../components/choc/LogoHeadlineTeaserVEO"
import { Modal2 } from "../../components/choc/ModalDVT"
import QuadTeaser from "../../components/choc/QuadTeaser"
import ScrollcompontentListelement from "../../components/choc/ScrollComponentListElementDental"
import ImgTextModule from "../../components/ImgTextModule"
import LayoutA from "../../components/layout/Layout"
import FadeInAnimation from "../../DefinitionComponents/Animation-Components/FadeInAnimation"
import ImgMaskReveal from "../../DefinitionComponents/Animation-Components/ImgMaskReveal"
import MaskSlidInAnimation from "../../DefinitionComponents/Animation-Components/MaskSlideInAnimation"
import RouterLink from "../../DefinitionComponents/Utility/Routing/RouterLink"
import InViewTrigger from "../../DefinitionComponents/Utility/UX/InViewTrigger"

const Dental = () => {
  let { toggleColorMode, ColorMode, setColorMode } = useColorMode()
  useEffect(() => {
    setColorMode(colormodeValue)
  })
  const [isToggledDVT, setToggleDVT] = useState(null)
  const [viewportHeight, setViewportHeight] = useState(0)
  const [pageHeight, setPageHeight] = useState(0)
  useEffect(() => {
    setViewportHeight(window.innerHeight)
    setPageHeight(document.body.clientHeight)
    // console.log('VIEWPORTHEIGHT: ' + window.innerHeight)
    // console.log('PAGEHEIGHT: ' + document.body.clientHeight)
  }, [])
  const [colormodeValue, changeColorModeValue] = useState("light")
  return (
    <LayoutA
      title={"Implantate | Jadeklinik"}
      description={
        "Ein fast perfekter Zahn. Implantate: die schönste und haltbarste Variante des Zahnersatzes. "
      }
    >
      <ImgTextModule
        src={
          "493557_Dentist_Patient_Sterile_Equipment_Dental_Clinic_By_M_ART_production_Artlist_HD.mp4"
        }
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        rounded="none"
        h="76vh"
        px={{ base: 0, md: 12, lg: 4, xl: 0 }}
        mediaOpacity={1}
        bgColor="brand.gray.100"
      >
        <Box p={{ base: 0, lg: "8" }}>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Text
              color="brand.tan.ulight"
              fontWeight="bold"
              fontSize={{ base: "6xl", lg: "8xl" }}
            >
              Implantate
            </Text>
          </FadeInAnimation>

          <Flex mt={0}>
            <Box>
              <MaskSlidInAnimation threshold={1} duration={1}>
                <Heading
                  mr={32}
                  mt={2}
                  color={"white"}
                  fontSize={{ lg: "2xl", md: "lg", base: "lg" }}
                  fontWeight="normal"
                  w={"100%"}
                  letterSpacing="tighter"
                >
                  Anatomisch korrekt. Optisch perfekt.
                </Heading>
              </MaskSlidInAnimation>
              <MaskSlidInAnimation threshold={1} duration={1}>
                <Heading
                  mr={32}
                  mt={2}
                  color={"white"}
                  fontSize={{ lg: "2xl", md: "lg", base: "lg" }}
                  fontWeight="normal"
                  w={"100%"}
                  letterSpacing="tighter"
                >
                  Funktionsbelastbar.
                </Heading>
              </MaskSlidInAnimation>
            </Box>
            <FadeInAnimation
              threshold={0.4}
              delay={0.5}
              duration={1.4}
              initialX={0}
              initialY={10}
            >
              <RouterLink
                isExternal={true}
                href="https://web.online-tis.de/start/M24437Yb6M"
              >
                <Button mt={4} variant="ghost" color="white">
                  Termine online
                </Button>
              </RouterLink>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={0.74}
              duration={1.4}
              initialX={0}
              initialY={10}
            >
              <RouterLink isExternal={false} link={"/kontakt/"}>
                <Button
                  ml={4}
                  mt={4}
                  variant="solid"
                  color="brand.violet.primary"
                  _hover={{
                    backgroundColor: "brand.violet.primary",
                    color: "white",
                  }}
                >
                  Kontakt
                </Button>
              </RouterLink>
            </FadeInAnimation>
          </Flex>
        </Box>
      </ImgTextModule>
      <LogoHeadlineTeaserVeo
        py={24}
        headline="Implantate"
        subheadline="Zahn für Zahn.
        "
      />

      <ImgMaskReveal
        imgsrc={"quang-tri-nguyen-T-iifFLk3KU-unsplash.jpg"}
        initScale={1}
        maxH={700}
        duration={1}
        delay={1}
      />
      <Container variant="layoutContainer" px={12} pt={12}>
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Was ist ein Implantat?"
          text1="Eine zahnwurzelförmige Schraube."
          text2="Die Möglichkeit, einzelne Zähne ohne Brücke oder Klammerprothese zu ersetzen (was immer mit Beschleifen gesunder Zahnsubstanz verbunden ist). Damit stellen Implantate die schönste und haltbarste Variante des Zahnersatzes dar.

          "
        />
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Ein fast perfekter Zahn"
          text1="Die anatomisch korrekte Replik."
          text2="Die Möglichkeit einzelne Zähne ohne Brücke oder Klammerprothese
          zu ersetzen (was immer mit Beschleifen gesunder Zahnsubstanz
          verbunden ist). Damit stellen Implanatate die schönste und
          haltbarste Variante des Zahnersatzes dar."
        />
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Perfekt belastbar"
          text1="Form follows Function."
          text2="Implantate sind voll belastbar. Von Echten nicht von außen, und auch für den Patienten im Alltag oft nicht zu unterscheiden.
          "
        />
      </Container>

      <Container variant="layoutContainer" minH="56vh">
        <ImgTextModule
          src={"223970.mp4"}
          mt={4}
          px={{ base: 4, md: 12, lg: 4, xl: 0 }}
          delay={0.3}
          duration={0.5}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          h="56vh"
          mediaOpacity={0.7}
          bgColor="brand.gray.vdark"
        >
          <Box p={{ base: 0, lg: "8" }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="white"
                fontWeight="normal"
                fontSize={{ base: "2xl", lg: "2xl" }}
              >
                Vor dem Eingriff
              </Text>
            </FadeInAnimation>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="white"
                fontWeight="normal"
                fontSize={{ base: "3xl", lg: "6xl" }}
              >
                Wie geht es weiter?
              </Text>
            </FadeInAnimation>

            <Flex
              mt={0}
              flexWrap="wrap"
              flexDirection={{ base: "flex-column", md: "flex-row" }}
            >
              <MaskSlidInAnimation threshold={1} duration={1}>
                <Heading
                  mr={32}
                  mt={2}
                  color={"white"}
                  fontSize={{ lg: "xl", md: "lg", base: "lg" }}
                  fontWeight="normal"
                  w={"100%"}
                  letterSpacing="tighter"
                >
                  Vom Beratungstermin, zur Ist-Zustand-Erfassung, der Planung
                  eines Behandlungsverfahrens mit der Hilfe von modernsten
                  Bildgebungsverfahren.
                </Heading>
              </MaskSlidInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={0.5}
                duration={1.4}
                initialX={0}
                initialY={10}
              >
                <RouterLink
                  isExternal={true}
                  href="https://web.online-tis.de/start/M24437Yb6M"
                >
                  <Button mt={4} variant="ghost" color="white">
                    Termine online
                  </Button>
                </RouterLink>
              </FadeInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={0.74}
                duration={1.4}
                initialX={0}
                initialY={10}
              >
                <RouterLink isExternal={false} link={"/kontakt/"}>
                  <Button
                    ml={4}
                    mt={4}
                    variant="solid"
                    color="brand.violet.primary"
                    _hover={{
                      backgroundColor: "brand.violet.primary",
                      color: "white",
                    }}
                  >
                    Kontakt
                  </Button>
                </RouterLink>
              </FadeInAnimation>
            </Flex>
          </Box>
        </ImgTextModule>
      </Container>

      <Container variant="layoutContainer" mt={0.5} py={12} mb={0.5} px={32}>
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Beratung"
          text1="Vor dem Eingriff"
          text2="Vor dem möglichen Eingriff findet ein Planungsgespräch statt. Hier stellen wir fest, welche diagnostischen Verfahren genutzt werden können. "
        />
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Individuell"
          text1=""
          text2="Es können einzelne Zähne ersetzt werden "
        />
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Übergreifend"
          text1=""
          text2="Es kann durch 4-6 Implantate ein vollständiger festsitzender Zahnersatz erstellt werden."
        />
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Schonend"
          text1=""
          text2="Einzelne Kieferabschnitte können restauriert werden, ohne gesundes Zahnmaterial in Mitleidenschaft zu ziehen."
        />
      </Container>

      <ImgTextModule
        src={
          "381150_Doctor_Technician_Imaging_Screen_By_Anna_Tolipova_Artlist_HD.mp4"
        }
        mt={4}
        px={{ base: 4, md: 12, lg: 4, xl: 0 }}
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h="76vh"
        rounded="none"
        mediaOpacity={0.7}
        bgColor="brand.gray.vdark"
      >
        <Box p={{ base: 0, lg: "8" }}>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Text
              color="white"
              fontWeight="normal"
              fontSize={{ base: "xl", lg: "xl" }}
            >
              Modernste Bildgebung.
            </Text>
          </FadeInAnimation>
          <FadeInAnimation
            threshold={0.4}
            delay={0.4}
            duration={0.7}
            initialX={0}
            initialY={-10}
          >
            <Text
              color="white"
              fontWeight="bold"
              fontSize={{ base: "5xl", lg: "6xl" }}
            >
              DVT
            </Text>
          </FadeInAnimation>

          <Flex
            mt={0}
            flexWrap="wrap"
            flexDirection={{ base: "column", md: "row" }}
            justifyContent="start"
          >
            <Box>
              <MaskSlidInAnimation threshold={1} duration={1}>
                <Heading
                  maxW={{ base: "100%", md: "50%" }}
                  mr={32}
                  mt={2}
                  color={"white"}
                  fontSize={{ lg: "2xl", md: "xl", base: "xl" }}
                  fontWeight="normal"
                  w={{ base: "100%", md: "50%" }}
                  letterSpacing="tight"
                >
                  Ein modernes Schichtbildverfahren in der Radiologie.
                </Heading>
              </MaskSlidInAnimation>
              <MaskSlidInAnimation threshold={1} duration={1}>
                <Heading
                  maxW={{ base: "100%", md: "50%" }}
                  mr={32}
                  mt={2}
                  color={"white"}
                  fontSize={{ lg: "lg", md: "lg", base: "lg" }}
                  fontWeight="normal"
                  w={{ base: "100%", md: "50%" }}
                  letterSpacing="tight"
                >
                  Wir empfehlen eine DVT (Hochauflösende Feinschicht
                  Computertomografische Aufnahme) Aufnahme anzufertigen anhand
                  der man mögliche Erkrankungen des Zahn-/Knochenapparates
                  erfassen kann.
                </Heading>
              </MaskSlidInAnimation>
            </Box>
            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
              <Button
                mt={4}
                onClick={() => setToggleDVT(true)}
                variant="ghost"
                color="white"
              >
                Was ist DVT ?
              </Button>
            </FadeInAnimation>
            <Spacer />
          </Flex>
        </Box>
      </ImgTextModule>

      <Modal2 isToggled={isToggledDVT} setToggle={setToggleDVT}></Modal2>
      <Container
        variant="layoutContainer"
        minH="56vh"
        mt={0.5}
        py={12}
        mb={0.5}
        px={32}
      >
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Planung"
          text1="Analyse und Kostendefintion"
          text2="im Anschluss findet das eigentliche Beratungsgespräch mit dem Chef Dr. Jann Voltmann statt; darin enthalten auch die evlt. Planung und dadurch entstehenden Kosten. "
        />
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Konzept"
          text1="Der große Tag."
          text2="Daraus wird ein Sanierungs & Restaurationskonzept erstellt. Das beinhaltet nicht erhaltungswürdige, zerstörte, wurzelkanalbehandelte Zähne, sowie Entzündungen des Zahnhalteapparates (Paradontose) Zysten zu analysieren und passende Massnahmen zu ergreifen."
        />
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Nach der OP."
          text1="Heilung und Nachsorge"
          text2="Nach der OP 3 - 6 Monate Einheilzeit, aufgrund ergänzenden Knochenaufbauenden Maßnahmen. "
        />
        <ScrollcompontentListelement
          sizevariant="wide"
          title="Was passiert danach?"
          text1="Heilung und Nachsorge"
          text2="Nach Fertigstellung der Arbeiten wird ein jährlicher Untersuchungstermin (Checkup) empfohlen bei dem Frühzeitig mögliche Probelme erkannt werden können. "
        />
      </Container>
      <Container variant="layoutContainer" minH="56vh">
        <ImgTextModule
          src={
            "493550_Tools_Dental_Clinic_Sterile_Equipment_Dentist_By_M_ART_production_Artlist_HD.mp4"
          }
          mt={4}
          px={{ base: 4, md: 12, lg: 4, xl: 0 }}
          delay={0.3}
          duration={0.5}
          threshold={0.3}
          initialY={10}
          initialX={10}
          video={true}
          h="56vh"
          mediaOpacity={0.7}
          bgColor="brand.gray.vdark"
        >
          <Box p={{ base: 0, lg: "8" }}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text
                color="white"
                fontWeight="normal"
                fontSize={{ base: "2xl", lg: "2xl" }}
              >
                Bereits ab:
              </Text>
            </FadeInAnimation>
            <InViewTrigger threshold={0.1} triggerOnce={false}>
              {inView => (
                <AnimatedCountUpDecimal
                  inView={inView}
                  unit="€"
                  fontSizeUnit="lg"
                  count={1500}
                  fontSize={"6rem"}
                  duration={1}
                  decimal={false}
                  decimalDuration={2}
                  decimalCount={0}
                  unitSpacing={3}
                  numberColor={"white"}
                  textColor={"white"}
                  cellHeight={"6rem"}
                  letterSpacing={"-0.2rem"}
                ></AnimatedCountUpDecimal>
              )}
            </InViewTrigger>
            <Flex mt={0}>
              <MaskSlidInAnimation threshold={1} duration={1}>
                <Heading
                  mr={32}
                  mt={2}
                  color={"white"}
                  fontSize={{ lg: "xl", md: "lg", base: "lg" }}
                  fontWeight="normal"
                  w={"100%"}
                  letterSpacing="tighter"
                >
                  Wir beraten Sie gern.
                </Heading>
              </MaskSlidInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={0.5}
                duration={1.4}
                initialX={0}
                initialY={10}
              >
                <RouterLink
                  isExternal={true}
                  href="https://web.online-tis.de/start/M24437Yb6M"
                >
                  <Button mt={4} variant="ghost" color="white">
                    Termine online
                  </Button>
                </RouterLink>
              </FadeInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={0.74}
                duration={1.4}
                initialX={0}
                initialY={10}
              >
                <RouterLink isExternal={false} link={"/kontakt/"}>
                  <Button
                    ml={4}
                    mt={4}
                    variant="solid"
                    color="brand.violet.primary"
                    _hover={{
                      backgroundColor: "brand.violet.primary",
                      color: "white",
                    }}
                  >
                    Kontakt
                  </Button>
                </RouterLink>
              </FadeInAnimation>
            </Flex>
          </Box>
        </ImgTextModule>
      </Container>

      <Container variant="layoutContainer" mt={0.5} py={12} mb={0.5}>
        <BannerVeoJadeklinik />
      </Container>

      <LogoHeadlineTeaser
        my={24}
        headline="Ästhetik mit High-Tech"
        subheadline="Und Verantwortung"
      />
      <BannerHyaloron mb={8} />
      <QuadTeaser />
    </LayoutA>
  )
}

export default Dental
